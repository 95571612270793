<template>
    <div class="form-group message-body-container">
           <table align="center" cellpadding="0" cellspacing="0" width="100%" style="background: #e8e6e6">
               <tbody>
                   <tr>
                     <td align="left" valign="top" width="100%">
                       <center>
                         <table cellspacing="0" cellpadding="0" width="100%" bgcolor="#e8e6e6">
                           <tbody>
                             <tr>
                             <td width="100%" height="80" valign="top" style="text-align: center; vertical-align: middle">
                               <center>
                                 <table cellpadding="0" cellspacing="0" width="600" style="height: 80px; background: #194576">
                                   <tbody><tr>
                                     <td style="vertical-align: middle; padding-left: 12px">
                                       <span style="color:white">Company Logo</span>
                                     </td>
                                   </tr>
                                 </tbody></table>
                               </center>
                             </td>
                           </tr>
                         </tbody></table>
                       </center>
                     </td>
                   </tr>
                   <tr>
                     <td align="center" valign="top" width="100%" style="background-color: #e8e6e6; padding-bottom: 5px">
                       <center>
                         <table cellspacing="0" cellpadding="0" width="600" style="background: #fff; line-height: 21px">
                           <tbody><tr>
                             <td style="padding: 10px 15px 0px; text-align: left; color: #777"> <br>
                               <span style="font-size: 14px; font-weight: bold; color: #333333"> Lorem Ipsum is simply dummy text</span>
                               <br>  <br>
                               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                               <br><br>
                             </td>
                           </tr>
                          
                         </tbody></table>
                       </center>
                     </td>
                   </tr>
                   
                  
                     <tr>
                     <td align="center" valign="top" width="100%">
                       <center>
                         <table cellpadding="0" cellspacing="0" width="600" style="background: #fff">
                             <tbody><tr>
                               <td style="padding: 20px 15px 20px 15px">
                                 <table cellspacing="0" cellpadding="0" width="100%" style="line-height: 21px">
                                   <tbody><tr>
                                     <td width="300" style="color: #26a4d3; font-size: 16px; font-weight: bold; text-align: left">
                                         Agent Comments
                                     </td>
                                     </tr>
                                     <tr>
                                       <td style="text-align: left; color: #777"><span style="padding-top: 20px; text-align: left; vertical-align: top; color: #777">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></td>
                                     </tr>
                                 </tbody></table>
                               </td>
                             </tr>
                 
                         </tbody></table>
                       </center>
                     </td>
                   </tr>
                   
                   <tr>
                     <td align="center" valign="top" width="100%" style="background: #e8e6e6; height: 100px">
                       <center>
                         <table cellspacing="0" cellpadding="0" width="600" style="background: #26a4d3; color: #fff; line-height: 21px">
                           <tbody><tr>
                             <td style="padding: 25px 25px 0px 25px; font-size: 18px; font-weight: 700px">
                               Please Note
                             </td>
                           </tr>
                           <tr>
                             <td style="padding: 10px 25px 0px">
                               <ul style="padding: 0; margin: 15px">
                                   <li>We accept credit cards (Mastercard, VISA, American Express, Discover, and Diners Club International), PayPal, and Bitcoin payment methods.</li>
                                   <li>A delivery date will be established after payment is confirmed. </li>
                                   <li>Configuration changes to the product quoted will require a new price quote. </li>
                                   <li>We guarantee the lowest price on any product we sell. Period.</li>
                               </ul>
                             </td>
                           </tr>
                           <tr>
                             <td style="font-size: 18px; font-weight: bold; text-align: center; color: #fff">YOUR FEEDBACK IS IMPORTANT TO US</td>
                           </tr>
                           <tr>
                             <td style="font-size: 14px; text-align: center; color: #89d2ea; padding-bottom: 50px">Let us know how we're doing.</td>
                           </tr>
                           <tr>
                             <td style="font-size: 14px; text-align: center; color: #fff; background: #292828; padding: 15px">
                               <a href="" style="margin-right: 15px; display: inline-block"><img src="https://image.ibb.co/mcUoLS/facebook.png"></a>
                               <a href="" style="margin-right: 15px; display: inline-block"><img src="https://image.ibb.co/h8NVZn/twitter.png"></a>
                               <a href="" style="margin-right: 15px; display: inline-block"><img src="https://image.ibb.co/hQReS7/linkedin.png"></a>
                               <a href="" style="display: inline-block"><img src="https://image.ibb.co/fOjYn7/instagram.png"></a>
                             </td>
                           </tr>
                         </tbody>
                       </table>
                   </center>
                       
               </td>
           </tr>
         </tbody>
           </table>
         </div>
   </template>
   
   <script>
   
     export default {
     name: 'EmailReminderComponents',
      components: {
          
     },
     data: function(){   
     }
   }
   
   </script>