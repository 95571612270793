<template>
    <div class="row">
   <div class="col-md-12">
      <div class="row">
         <!--v-if-->
         <div class="col-md-12">
            <div class="row">
               <div class="col-md-12">
                  <div class="row mbottom-15">
                     <div class="col-md-12">
                        <!--v-if-->
                        <div class="preview-body" style="overflow: auto;">
                           <center>
                              <table style="background: #f1f1f1; border: 0px solid #cccccc; color: #666666; width: 100%;" cellspacing="0" cellpadding="0" border="0">
                                 <tbody>
                                    <tr>
                                       <td>&nbsp;</td>
                                       <td style="width: 850px;" width="850">
                                          <center>
                                             <table style="background: rgb(255, 255, 255); border: 0px solid rgb(204, 204, 204); color: rgb(102, 102, 102); width: 100%; height: 1142.37px;" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                   <tr>
                                                      <td style="border-color: #ffffff; width: 99.7059%; ">&nbsp;</td>
                                                   </tr>
                                                   <tr>
                                                      <td style="border-color: #ffffff; width: 99.7059%; ">
                                                         <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                                            <tbody>
                                                               <tr>
                                                                  <td style="border-color: #ffffff; padding-left: 15px; font-size: 14px; font-family: Verdana, Geneva, Tahoma, sans-serif;">
                                                                     <span style="font-weight: bold;font-size:28px;">Insulation Estimate</span><br>
                                                                     <span style="font-weight: bold;">Goley INC.</span><br>
                                                                     <span>New York, 7878, street</span><br>
                                                                     <span>Phone - +440-09809</span><br>
                                                                     <span>Email - support@goley.com</span><br>
                                                                     <span>www.goleyinc.com</span><br>
                                                                  </td>
                                                                  <td style="padding-right: 50px;" align="right">
                                                                     <table cellspacing="0" cellpadding="0" border="0">
                                                                        <tbody>
                                                                           <tr>
                                                                              <td style="border-color: #ffffff; text-align: right;" align="right">
                                                                                 <img alt="logo" src="https://www.iservice.info/emailer_assets/goleyinc/emailer-assets/goley-logo.png">
                                                                              </td>
                                                                           </tr>
                                                                        </tbody>
                                                                     </table>
                                                                  </td>
                                                               </tr>
                                                               
                                                            </tbody>
                                                         </table>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td style="border-color: #ffffff; width: 99.7059%; ">&nbsp;</td>
                                                   </tr>
                                                   <tr>
                                                      <td style="border-color: #ffffff; padding-left: 50px; background: #c8102e; width: 99.7059%; ">&nbsp;</td>
                                                   </tr>
                                                   <tr>
                                                      <td style="border-color: #ffffff; width: 99.7059%; ">&nbsp;</td>
                                                   </tr>
                                                   <tr>
                                                      <td>
                                                         <table style="width: 100%;">
                                                            <tr> 
                                                                  <td style="width: 50%; padding-left:6px">
                                                                  <table style="font-size: 16px; width: 100%; border: 0px solid #f1f1f1;" width="100%" cellspacing="0" cellpadding="2" border="0">
                                                                     <tbody>
                                                                     <tr style="background-color: #ffffff;">
                                                                           <td style="font-family: Verdana; font-size: 16px; font-weight: bold; line-height: 24px; padding:0px  8px;color: #c8102e;"><span><strong>Customer Details</strong></span></td>
                                                                     </tr>  
                                                                     <tr style="background-color: #ffffff;">
                                                                           <td style="font-family: Verdana; font-size: 14px; line-height: 24px; padding: 0px 8px;"><span><strong>Name:</strong> Brian Adams </span></td>
                                                                     </tr>
                                                                     <tr style="background-color: #ffffff;">
                                                                           <td style="font-family: Verdana; font-size: 14px; line-height: 24px; padding:0px  8px;"><span><strong>Property Address:</strong> , AUSTIN, Texas, &nbsp;&nbsp;</span></td>
                                                                     </tr>
                                                                     
                                                                     <tr style="background-color: #ffffff;">
                                                                           <td style="font-family: Verdana; font-size: 14px; line-height: 24px; padding: 0px  8px;"><span><strong>Mobile Phone #:</strong> 2179034458 </span></td>
                                                                     </tr>
                                                                     <tr style="background-color: #ffffff;">
                                                                           <td style="font-family: Verdana; font-size: 14px; line-height: 24px; padding: 0px 8px;"><span><strong>Email Address:</strong> brian.adams@iservice.info </span></td>
                                                                     </tr>
                                                                     
                                                                     </tbody>
                                                                  </table>
                                                                  </td>
                                                                  <td style="width: 50%; vertical-align: top;">
                                                                  <table style="font-size: 16px; width: 100%; border: 0px solid #f1f1f1;" width="100%" cellspacing="0" cellpadding="2" border="0">
                                                                     <tbody>
                                                                     <tr style="background-color: #ffffff;">
                                                                           <td style="font-family: Verdana; font-size: 16px; font-weight: bold; line-height: 24px; padding:0px  8px;color: #c8102e;"><span><strong>Estimate Summary</strong></span></td>
                                                                     </tr>   
                                                                     <tr style="background-color: #ffffff;">
                                                                           <td style="font-family: Verdana; font-size: 14px; line-height: 24px; padding:0px  8px; "><span><strong>Date Estimate Provided:</strong> 2024-05-04</span></td>
                                                                     </tr>
                                                                        
                                                                     <tr style="background-color: #ffffff;">
                                                                           <td style="font-family: Verdana; font-size: 14px; line-height: 24px; padding: 0px 8px;"><span><strong>Estimate Number:</strong>7878888 </span></td>
                                                                     </tr>
                                                                     <tr style="background-color: #ffffff;">
                                                                           <td style="font-family: Verdana; font-size: 14px; line-height: 24px; padding: 0px 8px;"><span><strong>Total Amount:</strong>$2400.00 </span></td>
                                                                     </tr>
                                                                     <tr style="background-color: #ffffff;">
                                                                        <td style="font-family: Verdana; font-size: 14px; line-height: 24px; padding: 0px 8px;"><span><strong>Project Description:</strong>The insulation in my attic has been worn down by squirrels and needs updating.
                                                                        </span></td>
                                                                        </tr>
                                                                     </tbody>
                                                                  </table>
                                                                  </td>
                                                               </tr>
                                                         </table>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td style="border-color: #ffffff; width: 99.7059%; ">&nbsp;</td>
                                                   </tr>
                                                   <tr>
                                                      <td style="border-color: #ffffff; width: 99.7059%; ">&nbsp;</td>
                                                   </tr>
                                                   <tr>
                                                      <td style="border-color: #ffffff; width: 99.7059%; padding-left: 15px; ">
                                                         <span style="font-family: Verdana;font-size:16px; font-weight: bold; color: #C8102E; text-decoration: underline;">Estimate 1 Details</span>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td style="border-color: #ffffff; width: 99.7059%; padding:15px ">
                                                         <table style="font-size: 16px; width: 100%; border: 0px solid #ccc;  border-collapse: collapse;" width="100%" cellspacing="0" cellpadding="5" border="0">
                                                               <tbody>
                                                                  <tr style="background-color: #C8102E;">
                                                                     <td style="width: 150px;border: 1px solid #C8102E; color: #fff;  border-right-color: #fff;">
                                                                        <strong>Workarea </strong>
                                                                     </td>
                                                                     <td style="width: 150px;border: 1px solid #C8102E; color: #fff;  border-right-color: #fff;">
                                                                     <strong>Material</strong>
                                                                     </td>
                                                                     <td style="border: 1px solid #C8102E; color: #fff;  border-right-color: #fff;">
                                                                        <strong>Notes</strong>
                                                                     </td>
                                                                     <td style="width: 120px;border: 1px solid #C8102E; color: #fff;">
                                                                        <stong> Price</stong>
                                                                     </td>
                                                                  </tr>  
                                                                  <tr style="background-color: #ffffff;">
                                                                     <td style="width: 150px;border: 1px solid #ccc;">
                                                                        Base 
                                                                     </td>
                                                                     <td style="width: 150px;border: 1px solid #ccc;">
                                                                        Insulation
                                                                     </td>
                                                                     <td style="border: 1px solid #ccc;">
                                                                           Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                     </td>
                                                                     <td style="width: 120px;border: 1px solid #ccc;">
                                                                        $1400.00
                                                                     </td>
                                                                  </tr>  
                                                                  <tr style="background-color: #ffffff;">
                                                                     <td colspan="3" style="text-align: right; font-weight:bold; padding-right: 20px;">
                                                                        Services SubTotal 
                                                                     </td>
                                                                     <td style="width: 120px; font-weight:bold; ">
                                                                        $1400.00
                                                                     </td>
                                                                  </tr>  

                                                               </tbody>
                                                         </table>

                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td style="border-color: #ffffff; width: 99.7059%; ">&nbsp;</td>
                                                   </tr>
                                                   <tr>
                                                      <td style="border-color: #ffffff; width: 99.7059%; ">&nbsp;</td>
                                                   </tr>
                                                   <tr>
                                                      <td style="border-color: #ffffff; width: 99.7059%; padding-left: 15px; ">
                                                         <span style="font-family: Verdana;font-size:16px; font-weight: bold; color: #C8102E; text-decoration: underline;">Estimate 2 Details</span>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td style="border-color: #ffffff; width: 99.7059%; padding:15px ">
                                                        <table style="font-size: 16px; width: 100%; border: 0px solid #ccc;  border-collapse: collapse;" width="100%" cellspacing="0" cellpadding="5" border="0" >
                                                               <tbody>
                                                                  <tr style="background-color: #C8102E;">
                                                                     <td style="width: 150px;border: 1px solid #C8102E; color: #fff;  border-right-color: #fff;">
                                                                        <strong>Workarea </strong>
                                                                     </td>
                                                                     <td style="width: 150px;border: 1px solid #C8102E; color: #fff;  border-right-color: #fff;">
                                                                     <strong>Material </strong>
                                                                  </td>
                                                                     <td style="width: 70px;border: 1px solid #C8102E; color: #fff;  border-right-color: #fff;">
                                                                     <strong>Qty</strong>
                                                                     </td>
                                                                     <td style="border: 1px solid #C8102E; color: #fff;  border-right-color: #fff;">
                                                                        <strong>Price</strong>
                                                                     </td>
                                                                     <td style="border: 1px solid #C8102E; color: #fff;  border-right-color: #fff;">
                                                                     <strong>Discount %</strong>
                                                                     </td>
                                                                     <td style="width: 120px;border: 1px solid #C8102E; color: #fff;">
                                                                        <stong> Total</stong>
                                                                     </td>
                                                                  </tr>  
                                                                  <tr style="background-color: #ffffff;">
                                                                     <td style="width: 150px;border: 1px solid #ccc;">
                                                                        Base 
                                                                     </td>
                                                                     <td style="width: 150px;border: 1px solid #ccc;">
                                                                        Insulation material
                                                                     </td>
                                                                     <td style=" width:70px; border:  1px solid #ccc;">
                                                                        2
                                                                     </td>
                                                                     <td style="width: 120px;border: 1px solid #ccc;">
                                                                        $500.00
                                                                     </td>
                                                                     <td style="width: 120px;border: 1px solid #ccc;">
                                                                           0.00
                                                                        </td>
                                                                     <td style="width: 120px;border: 1px solid #ccc;">
                                                                           $1000.00
                                                                        </td>
                                                                  </tr>  
                                                                  <tr style="background-color: #ffffff;">
                                                                     <td style="width: 150px;border: 1px solid #ccc;">
                                                                        &nbsp; 
                                                                     </td>
                                                                     <td style="width: 150px;border: 1px solid #ccc;">
                                                                           &nbsp;
                                                                     </td>
                                                                     <td style=" width:70px; border:  1px solid #ccc;">
                                                                           &nbsp;
                                                                     </td>
                                                                     <td style="width: 120px;border: 1px solid #ccc;">
                                                                           &nbsp;
                                                                     </td>
                                                                     <td style="width: 120px;border: 1px solid #ccc;">
                                                                           &nbsp;
                                                                     </td>
                                                                     <td style="width: 120px;border: 1px solid #ccc;">
                                                                           &nbsp;
                                                                        </td>
                                                                  </tr>  
                                                                  <tr style="background-color: #ffffff;">
                                                                     <td style="width: 150px;border: 1px solid #ccc;">
                                                                        &nbsp; 
                                                                     </td>
                                                                     <td style="width: 150px;border: 1px solid #ccc;">
                                                                           &nbsp;
                                                                     </td>
                                                                     <td style=" width:70px; border:  1px solid #ccc;">
                                                                           &nbsp;
                                                                     </td>
                                                                     <td style="width: 120px;border: 1px solid #ccc;">
                                                                           &nbsp;
                                                                     </td>
                                                                     <td style="width: 120px;border: 1px solid #ccc;">
                                                                           &nbsp;
                                                                     </td>
                                                                     <td style="width: 120px;border: 1px solid #ccc;">
                                                                           &nbsp;
                                                                        </td>
                                                                  </tr>  
                                                                  <tr style="background-color: #ffffff;">
                                                                     <td style="width: 150px;border: 1px solid #ccc;">
                                                                        &nbsp; 
                                                                     </td>
                                                                     <td style="width: 150px;border: 1px solid #ccc;">
                                                                           &nbsp;
                                                                     </td>
                                                                     <td style=" width:70px; border:  1px solid #ccc;">
                                                                           &nbsp;
                                                                     </td>
                                                                     <td style="width: 120px;border: 1px solid #ccc;">
                                                                           &nbsp;
                                                                     </td>
                                                                     <td style="width: 120px;border: 1px solid #ccc;">
                                                                           &nbsp;
                                                                     </td>
                                                                     <td style="width: 120px;border: 1px solid #ccc;">
                                                                           &nbsp;
                                                                        </td>
                                                                  </tr>  

                                                                  <tr style="background-color: #ffffff;">
                                                                     <td colspan="5" style="text-align: right; font-weight:bold; padding-right: 20px;">
                                                                        SubTotal 
                                                                     </td>
                                                                     <td style="width: 120px; font-weight:bold; ">
                                                                        $1000.00
                                                                     </td>
                                                                  </tr>  
                                                                  <tr style="background-color: #ffffff;">
                                                                     <td colspan="5" style="text-align: right; font-weight:bold; padding-right: 20px;">
                                                                        Total Discount
                                                                     </td>
                                                                     <td style="width: 120px; font-weight:bold; ">
                                                                        00.00
                                                                     </td>
                                                                  </tr>  
                                                                  <tr style="background-color: #ffffff;">
                                                                     <td colspan="5" style="text-align: right; font-weight:bold; padding-right: 20px;">
                                                                        Total
                                                                     </td>
                                                                     <td style="width: 120px; font-weight:bold; ">
                                                                           $1000.00
                                                                     </td>
                                                                  </tr>  

                                                               </tbody>
                                                         </table>

                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td style="border-color: #ffffff; width: 99.7059%; ">
                                                         <p style="padding:15px;">
                                                            <span style="font-weight: bold;">Comments from your salesperson:</span><br>  I recommend going with the base only to see if that corrects your issue. We can come back and to the other items if you still need more.
                                                         </p>
                                                      </td>
                                                      </tr>
                                                      <tr>
                                                         <td style="border-color: #ffffff; width: 99.7059%; ">
                                                            &nbsp;
                                                         </td>
                                                      </tr>
                                                   
                                                   <tr>
                                                      <td style="border-color: rgb(255, 255, 255); width: 99.7059%; height: 39.1875px;">
                                                         <div style="text-align: right; padding-right: 15px;"><a href="">Terms & Conditions</a></div>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td style="border-color: #ffffff; width: 99.7059%; ">&nbsp;</td>
                                                   </tr>
                                                   <tr>
                                                      <td style="border-color: #ffffff; width: 99.7059%; ">&nbsp;</td>
                                                   </tr>
                                                   <tr>
                                                      <td style="width: 99.7059%;">
                                                         <table style="font-size: 16px; background-color: #000000;" width="100%" cellspacing="0" cellpadding="2" border="0">
                                                            <tbody>
                                                               <tr>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                               </tr>
                                                               <tr>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                               </tr>
                                                               <tr>
                                                                  <td style="width: 50px;">&nbsp;</td>
                                                                  <td style="border-color: #ffffff; text-align: center;"><img src="https://iservice.info/emailer_assets/goleyinc/emailer-assets/50years.png"></td>
                                                                  <td style="width: 50px;">&nbsp;</td>
                                                               </tr>
                                                               <tr>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                               </tr>
                                                               <tr>
                                                                  <td style="width: 50px;">&nbsp;</td>
                                                                  <td style="border-color: #ffffff;">
                                                                     <p style="font-size: 12px; text-align: center; color: #ffffff; line-height: 22px;">© 2023 GOLEY INSULATION. ALL RIGHTS RESERVED.<br>1707 Bluffview Drive, Dupo, IL 62239</p>
                                                                  </td>
                                                                  <td style="width: 50px;">&nbsp;</td>
                                                               </tr>
                                                               <tr>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                                  <td>&nbsp;</td>
                                                               </tr>
                                                            </tbody>
                                                         </table>
                                                      </td>
                                                   </tr>
                                                </tbody>
                                             </table>
                                          </center>
                                       </td>
                                       <td>&nbsp;</td>
                                    </tr>
                                 </tbody>
                              </table>
     
                           </center>
                        </div>
                        <!--v-if-->
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

   </template>
   
   <script>
   
     export default {
     name: 'EstimateQuotePreview',
      components: {
          
     },
     data: function(){   
     }
   }
   
   </script>